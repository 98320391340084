<div class="technicians-list-modal">
    <i class="material-icons close" (click)="close()">close</i>
    <div class="modal-title">{{ 'Select Technicians' | translate }}</div>

    <div class="search-results-container" (scroll)="onScroll($event)">
        @if(techniciansList.length > 0) { @for (technician of techniciansList; track technician.technicianId) {
        <technician-card
            [ngClass]="{ selected: isTechnicianSelected(technician) }"
            [technician]="technician"
            [isAbleToSeeTechnicianFullProfile]="false"
            (toggleSelected)="toggleSelected(technician)"
        ></technician-card>
        } } @if (techniciansList.length === 0 && !isLoading()) {
        <div class="no-results">
            <h3 class="h3">No technicians available!</h3>
        </div>
        }
    </div>
    @if (isLoading()) {
    <ng-container>
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </ng-container>
    }
    <ng-container *ngIf="shouldDisplayLabourAcknowledge">
        <div class="disclaimer">
            <input
                [(ngModel)]="labourAcknowledgeClicked"
                [ngModelOptions]="{ standalone: true }"
                id="agency-labour-acknowledge"
                type="checkbox"
            />
            <label for="agency-labour-acknowledge">
                As the representative agency, we ensure the technician acknowledges it is his/her responsibility to
                ensure compliance with all applicable regulations (such as those related to social securities and
                personal tax coverage) originating from this assignment and holds the receiving Aviation Company
                harmless in case of non-compliance.
            </label>
        </div>
        <div *ngIf="displayLabourAcknowledgeError" class="text-danger text-center">
            You need to acknowledge to proceed with the application.
        </div>
    </ng-container>
    <general-button
        style="width: 120px"
        (onClick)="confirm()"
        [isDisabled]="!canSubmit"
        class="confirm-btn"
        text="Confirm"
    ></general-button>
</div>
