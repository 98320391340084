<i (click)="close()" class="material-icons close pointer">close</i>
<div *ngIf="offer && facilityProfile" class="job-offer-card-modal">
    <ng-container [ngSwitch]="offer.offerType">
        <ng-container
            *ngSwitchCase="OFFER_TYPE.TEMPORARY"
            [ngTemplateOutlet]="temporaryAndPermanentOffer"
        ></ng-container>
        <ng-container
            *ngSwitchCase="OFFER_TYPE.PERMANENT"
            [ngTemplateOutlet]="temporaryAndPermanentOffer"
        ></ng-container>
        <ng-container *ngSwitchCase="OFFER_TYPE.AGENCY" [ngTemplateOutlet]="agencyOffer"></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="packageOffer"></ng-container>
    </ng-container>
</div>

<ng-template #temporaryAndPermanentOffer>
    <ng-container *ngTemplateOutlet="temporaryAndAgencyOfferInfo"></ng-container>

    <div *ngIf="applyUrl" class="apply-wrapper">
        <ng-container *ngIf="isUserTechnician()">
            <ng-container *ngIf="isStaffnowBrand()">
                <div class="disclaimer">
                    <input
                        [(ngModel)]="labourRegulationsAcknowledgmentClicked"
                        [ngModelOptions]="{ standalone: true }"
                        class="pointer"
                        id="labour-acknowledge"
                        type="checkbox"
                    />
                    <label for="labour-acknowledge" class="pointer">
                        {{ 'SYSTEM.INFO.TECHNICIAN_LABOUR_ACKNOWLEDGMENT' | translate }}
                    </label>
                </div>
                <div *ngIf="displayAcknowledgeErrorMessage" class="text-danger text-center">
                    {{ 'SYSTEM.WARNING.TECHNICIAN_LABOUR_ACKNOWLEDGMENT' | translate }}
                </div>
            </ng-container>
            <general-button
                (onClick)="applyToTemporaryOfferAsTechnician()"
                [isDisabled]="isApplyAsTechnicianDisabled()"
                class="apply-btn"
                text="APPLY"
            ></general-button>
        </ng-container>

        <ng-container *ngIf="isUserAgency()">
            <general-button
                (onClick)="applyToTemporaryOfferWithOwnTechnicians()"
                [isLoading]="isLoading()"
                [isDisabled]="isLoading()"
                class="apply-btn"
                text="APPLY WITH OWN TECHNICIANS"
            ></general-button>
        </ng-container>
    </div>
</ng-template>

<ng-template #agencyOffer>
    <ng-container *ngTemplateOutlet="temporaryAndAgencyOfferInfo"></ng-container>
    <div class="apply-wrapper">
        <general-button
            (onClick)="applyOfferAsTechnicianWithoutAcknowledge()"
            class="apply-btn"
            text="APPLY"
        ></general-button>
    </div>
</ng-template>

<ng-template #packageOffer>
    <staffnow-package-offer-info
        [applicationExistsMessage]="applicationExistsMessage"
        [applicationExists]="offer.loggedInAgencyCanApply === false"
        [offer]="offer"
    ></staffnow-package-offer-info>
    <div class="apply-wrapper">
        <general-button
            *ngIf="applyUrl"
            (onClick)="applyToPackageOffer()"
            class="apply-btn"
            text="APPLY"
        ></general-button>
    </div>
</ng-template>

<ng-template #temporaryAndAgencyOfferInfo>
    <staffnow-job-offer-info
        [applicationExistsMessage]="applicationExistsMessage"
        [applicationExists]="offer.canLoggedInTechnicianApply === false"
        [displayOfferLocation]="true"
        [offer]="offer"
    ></staffnow-job-offer-info>
</ng-template>
