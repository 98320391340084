import { createAction, props } from '@ngrx/store'
import { OverviewEntries } from '@platform/shared/constants/overview-entries.enum'
import { PackageOfferDto } from '@libs/shared/models/offer.model'

export const ListLoaded = createAction(
  '[Search Overview] search list loaded',
  props<{ response: any }>()
)
export const FailedToLoadList = createAction(
  '[Search Overview] search list failed to load'
)
export const UpdateList = createAction(
  '[Search Overview] Update search list',
  props<{ filtersValue: any, entry: OverviewEntries }>()
)
export const GetAgencyTechnicians = createAction(
  '[Search Overview] Get agency technicians',
  props<{ getAgencyTechniciansUrl: string, offer: any }>()
)
export const SuccessfullyLoadedAgencyTechnicians = createAction(
  '[Search Overview] Agency technicians list loaded',
  props<{ techniciansList: any[], offer: any }>()
)
export const FailedToLoadAgencyTechnicians = createAction(
  '[Search Overview] Failed to load agency technicians'
)
export const SubmitTemporaryJobApplicationAsTechnician = createAction(
  '[Search Overview] SubmitTemporaryJobApplicationAsTechnician',
  props<{ offer: any, agency: any, experienceLetterWanted: boolean, labourRegulationsAcknowledgment: boolean }>()
)
export const SubmitAgencyJobApplication = createAction(
  '[Search Overview] SubmitAgencyJobApplication',
  props<{ offer: any }>()
)
export const SubmitPermanentJobApplication = createAction(
  '[Search Overview] SubmitPermanentJobApplication',
  props<{ offer: any }>()
)
export const SubmitPackageOfferApplication = createAction(
  '[Search Overview] SubmitPackageOfferApplication',
  props<{
    offer: PackageOfferDto
    startDate: Date
    endDate: Date
    agencyOfferedPrice: number
    agencyNotes?: string
    priceEstimate: boolean
  }>()
)
export const SubmitTemporaryJobApplicationAsAgency = createAction(
  '[Search Overview] SubmitTemporaryJobApplicationAsAgency',
  props<{ offer: any, technicians: string[], labourRegulationsAcknowledgment: boolean }>()
)
export const SuccessfulApplication = createAction(
  '[Search Overview] Successful job application'
)
export const FailedApplication = createAction(
  '[Search Overview] Failed job application'
)
export const AddTechniciansToOffer = createAction(
  '[Search Overview] Add technicians to offer',
  props<{ addToOfferUrl: string, techniciansMap: any }>()
)
export const SuccessfullyAddedTechniciansToOffer = createAction(
  '[Search Overview] Successfully added technicians to the offer'
)
export const FailedToAddTechniciansToOffer = createAction(
  '[Search Overview] Failed to add technicians to the offer'
)
export const UpdateMroFavoriteTechniciansAndReloadSearch = createAction(
  '[Search Overview] Update list of favorite technicians',
  props<{
    technicianUuid: string
    url: string
    filtersValue: any
    entry: any
  }>()
)
