import { Component, Input, OnInit, signal } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { TechnicianProfileModel, UserProfile } from '@libs/shared/models/technician-profile.model';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { isNil } from 'lodash-es';
import { BrandName } from '@libs/shared/bms-common/environment/environment.model';
import { Store } from '@ngrx/store';
import { getBrandName } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';

@UntilDestroy()
@Component({
  selector: 'staffnow-technicians-list-modal',
  templateUrl: './technicians-list-modal.component.html',
  styleUrls: ['./technicians-list-modal.component.scss']
})
export class TechniciansListModalComponent implements OnInit {
  private page = 0;
  private hasNextPage = true;
  @Input() public loadMoreCallback: (page: number) => Observable<any>;
  @Input() public techniciansList: TechnicianProfileModel[] = [];
  @Input() public onConfirmCallback: (selectedTechnicians: string[], labourRegulationsAcknowledgment: boolean) => void =
    null;
  @Input() public jobTypeRequiresLabourAcknowledge: boolean = false;
  public displayLabourAcknowledgeError: boolean = false;
  public labourAcknowledgeClicked: boolean = false;
  public isStaffnowBrand: boolean = false;
  public selectedTechnicians: any[] = [];
  public isLoading = signal(false);
  private previousScrollPosition = null;

  get shouldDisplayLabourAcknowledge(): boolean {
    return this.isStaffnowBrand && this.jobTypeRequiresLabourAcknowledge;
  }

  constructor(public bsModalRef: BsModalRef, private readonly store: Store<any>) {
    this.store.pipe(getBrandName, untilDestroyed(this)).subscribe(brandName => {
      this.isStaffnowBrand = brandName === BrandName.StaffNow;
    });
  }

  ngOnInit(): void {
    this.isLoading.update(() => true);
    this.loadMoreCallback(this.page).subscribe(data => {
      this.isLoading.update(() => false);
      this.hasNextPage = data.hasNext;
      this.techniciansList = getEmbeddedResource(data, JobOfferLinkRel.Technicians);
    });
  }

  public toggleSelected(technician): void {
    const profile: UserProfile = getEmbeddedResource(technician, UserProfileLinkRel.Profile);

    if (this.isTechnicianSelected(technician)) {
      const technicianUuidIndex = this.selectedTechnicians.indexOf(profile.userUuid);
      this.selectedTechnicians.splice(technicianUuidIndex, 1);
    } else {
      this.selectedTechnicians.push(profile.userUuid);
    }
  }

  public onScroll($event: any): void {
    const { scrollTop, clientHeight, scrollHeight } = $event.target;
    const isScrollingDown = (this.previousScrollPosition ?? 0) - $event.target.scrollTop < 0;
    if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 200) {
      if (!this.isLoading() && this.hasNextPage) {
        this.isLoading.update(() => true);
        this.page++;
        this.loadMoreCallback(this.page).subscribe(data => {
          const newTechnicians: TechnicianProfileModel[] = getEmbeddedResource(data, JobOfferLinkRel.Technicians);
          this.techniciansList = [...this.techniciansList, ...newTechnicians];
          this.hasNextPage = data.hasNext;
          this.isLoading.update(() => false);
        });
      }
    }
    this.previousScrollPosition = $event.target.scrollTop;
  }

  public confirm(): void {
    if (!this.shouldDisplayLabourAcknowledge || this.labourAcknowledgeClicked) {
      if (!isNil(this.onConfirmCallback)) {
        this.onConfirmCallback(this.selectedTechnicians, this.labourAcknowledgeClicked);
      }
      this.close();
    } else {
      this.displayLabourAcknowledgeError = true;
    }
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public isTechnicianSelected(technician: TechnicianProfileModel): boolean {
    const userProfile: UserProfile = getEmbeddedResource(technician, UserProfileLinkRel.Profile);
    return this.selectedTechnicians.includes(userProfile.userUuid);
  }

  get canSubmit(): boolean {
    return (
      this.selectedTechnicians.length > 0 && (!this.shouldDisplayLabourAcknowledge || this.labourAcknowledgeClicked)
    );
  }
}
