import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { AmeTitle } from '@libs/shared/models/ame-title.model';
import { UserPhone } from '@libs/shared/models/user-phone.model';

export const ACCOUNT_FORM_ID = 'accountForm';

export interface AccountFormModel {
  firstName: string;
  lastName: string;
  presentation: string;
  userPhone: UserPhone;
  country: string;
  state: string;
  street: string;
  city: string;
  zipCode: string;
  apartment: string;
  ameTitles: AmeTitle[];
  experience: number;
  airplanes: Array<string>;
  languages: Array<number>;
  preferredWorkShifts?: Array<number>;
  preferredContractType?: number;
  preferredPayRangeMin?: number;
  preferredPayRangeMax?: number;
  otherAirplanes: string;
  role: string;
  position: string;
  defaultUser: boolean;
  isStaffie?: boolean;
  onlyDefaultSet: boolean;
  apiRegion: string;
  isTcn: boolean;
  licenses?: Array<number>;
  licenseNumber?: string;
}

export interface FormState extends FormGroupState<AccountFormModel> {}

export const initialFormState: FormState = createFormGroupState<AccountFormModel>('accountForm', {
  firstName: '',
  lastName: '',
  presentation: '',
  userPhone: null,
  country: '',
  state: '',
  city: '',
  zipCode: '',
  apartment: '',
  street: '',
  ameTitles: [],
  experience: null,
  airplanes: [],
  languages: [],
  otherAirplanes: '',
  role: '',
  position: null,
  defaultUser: false,
  onlyDefaultSet: false,
  apiRegion: null,
  isTcn: false
});
